import { useQueryClient, type DefaultOptions } from "@/utils/@tanstack/react-query";

import { ErrorHandler } from "@/utils/errorHandler";

import {  getLoggedToken, getLoggedTokenPublic } from "./common";
import { Locale } from "@/i18n/i18n-config";

const normalizedData = (
  data: any,
  normalize: {
    item: string;
    key: string;
    child?: { item: string; key: string; parent?: string }[];
  }[],
) => {
  const normalizedPath: any = {};
  const normalizePath = (arr: [], key: string) =>
    arr.reduce((previousValue: any, currentValue: any) => {
      const value = previousValue;
      value[currentValue[key]] = currentValue;
      return value;
    }, {});

  normalize.forEach((item) => {
    normalizedPath[item.item] = normalizePath(data[item.item], item.key);

    if (item.child !== undefined) {
      Object.entries(normalizedPath[item.item]).forEach(([iN]) => {
        item.child?.forEach((child) => {
          if (child.parent !== undefined) {
            const parentString = child.parent;
            normalizedPath[item.item][iN][child.parent].forEach(
              (_p: any, pi: any) => {
                normalizedPath[item.item][iN][parentString][pi][child.item] =
                  normalizePath(
                    normalizedPath[item.item][iN][parentString][pi][child.item],
                    child.key,
                  );
              },
            );
          } else {
            normalizedPath[item.item][iN][child.item] = normalizePath(
              normalizedPath[item.item][iN][child.item],
              child.key,
            );
          }
        });
      });
    }
  });

  return { ...data, ...normalizedPath };
};
export interface FetchDataInterface {
  publicToken?: boolean;
  queryType?: string;
  tokenApi?: string;
  select?: string | null;
  normalize?: {
    item: string;
    key: string;
    child?: { item: string; key: string; parent?: string }[];
  }[];
  method?: string;
  path?: string;
  url?: string | null;
  body?: object | null;
  locale?: Locale;
}

export const fetchData = async (d: FetchDataInterface) => {
  const {
    normalize = [],
    select = null,
    method = "GET",
    body = null,
    publicToken = false,
    tokenApi = '',
    path = "",
    url = null,
    locale = "en",
  } = d;
  try {  
    const api = process.env.NEXT_PUBLIC_API_HOST;

    const config: any = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Cache-Control": "no-store",
        // cache: "no-store" 
      },
      method,
      credentials: "omit",
       next: { revalidate: 300 },
    };
    const token = getLoggedToken();

    if (publicToken) {
      const tokenPublic = getLoggedTokenPublic();
      config.headers["x-api-event-public"] = `${tokenPublic}`;
    } else {
      if (token) {
        config.headers["x-api-event"] = `${token}`;
      } else if (tokenApi !== "") {
        config.headers["x-api-event"] = `${tokenApi}`;
      } 
    }
  
    if (body) {
      config.body = JSON.stringify(body);
    }
    const getURL = () => {
      return url ? `${url.includes('http') ? '' : api}${url}` : `${api}${path}${path.includes('?') ? '&' : '?'}lang=${locale}`;
    };

    const fetchUrl = getURL();
    const res = await fetch(fetchUrl, config);
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      if (data.error) {
        throw new ErrorHandler(data, data.error.code);
      }
      let dataResponse = data;
      // if (data.meta) {
      //   dataResponse.meta = data.meta;
      // }
      // if (data.links) {
      //   dataResponse.links = data.links;
      //   dataResponse.data = data.data;
      // }
      if (select) {
        dataResponse = dataResponse[select];
      }
      if (normalize.length > 0) {
        dataResponse = normalizedData(dataResponse, normalize);
      }

      return dataResponse;
    }

    if (res.status >= 400 && res.status <= 499) {
      const data = await res.json();
      throw new ErrorHandler(data, res.status); return null;
    }
    throw new ErrorHandler({ message: "requestFailed" }, res.status);
  } catch (e: any) {
    throw new ErrorHandler(e.message, e.status);
  }
};

export interface CustomDefaultOptions extends DefaultOptions<Error> {
  locale?: Locale;
}

export const defaultOptions = (
  locale: Locale,
): { defaultOptions: CustomDefaultOptions } => {
  return {
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false,
      },
      locale,
    },
  };
};
