import { Locale, i18n } from "@/i18n/i18n-config";
import Cookies from "js-cookie";
import InitInterface from "@/types/initInterface";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

export const isNumericString = (value: string): boolean => {
  return typeof value === 'string' && !Number.isNaN(Number(value));
};

const testJSON = (text: any) => {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    const data = JSON.parse(text);
    return data;
  } catch (error) {
    return false;
  }
};

const tryLocalStorage = () => {
  try {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const generateUrl = (url: string, lang: Locale = 'en') => {
  if (lang === i18n.defaultLocale) {
    return url;
  }
  return `/${lang}${url}`
}

export const getDate = (
  timestamp: string
): {
  month: string;
  day: number;
  weekday: string;
  year: number;
  monthInt: number;
  hours: number | string;
  minutes: number | string;
  relative: string;
  minutesAgo: number;
} => {
  const date = new Date(timestamp);

  const monthInt = date.getMonth() + 1;
  let month = '';
  let relative = '';
  const minutesAgo = Math.floor(((date.getTime() - today.getTime()) / 60000) * -1);
  const day = date.getDate();
  let hours: number | string = date.getHours();
  let minutes: number | string = date.getMinutes();
  let weekday = '';
  const year = date.getFullYear();
  if (date.toLocaleString !== undefined) {
    month = date.toLocaleString('en', { month: 'short' });
    weekday = date.toLocaleString('en', { weekday: 'short' });
  }
  if (minutesAgo < 5) {
    relative = 'Now';
  } else if (minutesAgo < 60) {
    relative = `${minutesAgo}min`;
  } else if (minutesAgo < 120) {
    relative = `1h`;
  } else if (year === today.getFullYear() && date.getMonth() === today.getMonth() && day === today.getDate()) {
    relative = 'Today';
  } else if (year === yesterday.getFullYear() && date.getMonth() === yesterday.getMonth() && day === yesterday.getDate()) {
    relative = 'Yesterday';
  }

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return { month, day, weekday, year, monthInt, hours, minutes, relative, minutesAgo };
};

const cssVars: any = {};
export const getCssVar = (val: string) => {
  if (typeof window !== 'undefined') {
    if (cssVars[val]) {
      return cssVars[val];
    }
    const style = getComputedStyle(document.documentElement).getPropertyValue(val);
    cssVars[val] = style;
    return style;
  }
  return '';
};

export const setToLocalStorage = (key: string, data: any) => {
  if (tryLocalStorage() === true && typeof window !== 'undefined' && window.localStorage) {
    try {
      window.localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      // console.log('Local Storage is full, Please empty data');
    }
  }
};
export const getFromLocalStorage = (key: string) => {
  if (tryLocalStorage() === true && typeof window !== 'undefined' && window.localStorage) {
    return JSON.parse(window.localStorage.getItem(key) as string);
  }
  return null;
};
export const removeFromLocalStorage = (key: string) => {
  if (tryLocalStorage() === true && typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.removeItem(key);
  }
};

export const setTokens = (token: InitInterface['data']['hash']) => {
  if (token) {
    Cookies.set(
      `${process.env.NEXT_PUBLIC_ENVIRONMENT}_token`,
      token
    );
  }
};


export const setTokensPublic = (token: string) => {
  if (token) {
    Cookies.set(
      `${process.env.NEXT_PUBLIC_ENVIRONMENT}_token_public`,
      token
    );
  }
};
export const setFanCodeForEvent = (code: string, eventHash: string) => {
  if (code && eventHash) {
    Cookies.set(
      `${process.env.NEXT_PUBLIC_ENVIRONMENT}_code_${eventHash}`,
      code
    );
  }
};
export const getFanCodeForEvent = (eventHash: string | undefined) => {
  let token: any = typeof window !== 'undefined' && Cookies.get(`${process.env.NEXT_PUBLIC_ENVIRONMENT}_code_${eventHash}`);
  if (token) {
    return token;
  }
  return false;
};
export const removeFanCodeForEvent = (eventHash: string | undefined) => {
  Cookies.remove(`${process.env.NEXT_PUBLIC_ENVIRONMENT}_code_${eventHash}`, {
    path: '/',
  });
};


export const removeUserData = () => {
  Cookies.remove(`${process.env.NEXT_PUBLIC_ENVIRONMENT}_token`, {
    path: '/',
  });
};

export const checkIfLogged = (): boolean => {
  return !!(typeof window !== 'undefined' && Cookies.get(`${process.env.NEXT_PUBLIC_ENVIRONMENT}_token`));
};

export const getLoggedToken = ():   string  | false => {
  let token: any = typeof window !== 'undefined' && Cookies.get(`${process.env.NEXT_PUBLIC_ENVIRONMENT}_token`);
  if (token) {
    return token;
  }
  return false;
};

export const getLoggedTokenPublic = ():   string  | false => {
  let token: any = typeof window !== 'undefined' && Cookies.get(`${process.env.NEXT_PUBLIC_ENVIRONMENT}_token_public`);
  if (token) {
    return token;
  }
  return false;
};


export const isNative = () => {
  return !!(typeof window !== 'undefined' && window.navigator && typeof window.navigator.share === 'function' && window.innerWidth < 768);
};

// export const transformPaginationData = (nestedItems: any) => {
//   if (nestedItems && nestedItems.pages.length > 0) {
//     const newest = nestedItems.pages.length - 1;

//     const additionalAttributes = Object.keys(nestedItems.pages[newest]).filter((attribute) => !['items', 'meta'].includes(attribute));

//     const data: any = {
//       pages: nestedItems.pages,
//       pagination: nestedItems.pages[newest]?.meta.pagination,
//     };

//     if (additionalAttributes.length > 0) {
//       additionalAttributes.forEach((attribute) => {
//         data[attribute] = nestedItems.pages[newest][attribute];
//       });
//     }

//     return {
//       ...data,
//       pages: data.pages.map((page: any) => {
//         return { items: page.items };
//       }),
//     };
//   }
//   return null;
// };


export const interpolateUrl = (string: string, values: { [key: string]: string }) => {
  return string.replace(/{(.*?)}/g, (match, _offset) => {
    const matchWithoutCurlyBraces = match.replace(/[{}]/g, '');

    return values[matchWithoutCurlyBraces] && !values[matchWithoutCurlyBraces].includes('undefined') ? values[matchWithoutCurlyBraces] : '';
  });
};

export const arraysHaveSameValues = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  for (let i = 0; i < sortedArr1.length; i += 1) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
};

export const generateYearArray = (): number[] => {
  const startYear = 1923;
  const endYear = today.getFullYear() - 11;
  const years: number[] = [];
  for (let year = endYear; year >= startYear; year -= 1) {
    years.push(year);
  }
  return years;
};
